$elexon-red: #ff4040;
$danger: $elexon-red;

@import "bulma/bulma.sass";
@import "~react-datepicker/dist/react-datepicker.css";

@import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,300i,400,400i,600,600i,700,700i");

@font-face {
  font-family: "DIN Next LT W01";
  src: url("../assets/fonts/fa5ed46b-792d-4f47-894e-fa587cf4e1cf.eot?#iefix");
  src: url("../assets/fonts/fa5ed46b-792d-4f47-894e-fa587cf4e1cf.eot?#iefix")
      format("eot"),
    url("../assets/fonts/03008527-67bd-478e-98e3-3b56dd9a6520.woff2")
      format("woff2"),
    url("../assets/fonts/557f817d-9c25-4a23-baaf-a3cf84a7cd7c.woff")
      format("woff"),
    url("../assets/fonts/92ed1479-1d40-4a94-8baf-6abd88b17afa.ttf")
      format("truetype"),
    url("../assets/fonts/36ae914b-b535-47cd-b3d2-8c43073d8634.svg#36ae914b-b535-47cd-b3d2-8c43073d8634")
      format("svg");
  font-weight: bold;
}
@font-face {
  font-family: "elexon-icons";
  src: url("../assets/fonts/elexon-uat.eot");
  src: url("../assets/fonts/elexon-uat.eot?#iefix") format("embedded-opentype"),
    url("../assets/fonts/elexon-uat.woff") format("woff"),
    url("../assets/fonts/elexon-uat.ttf") format("truetype"),
    url("../assets/fonts/elexon-uat.svg#elexon-uat") format("svg");
  font-weight: normal;
  font-style: normal;
}

html {
  font-size: 1rem;
}

h1,
h2,
h3 {
  font-family: "DIN Next LT W01", "Source Sans Pro", sans-serif;
}

body,
button {
  margin: 0;
  font-family: "Source Sans Pro", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.is-link {
  text-decoration: underline;
}

$elexon-purple: #64358c;
h2.title {
  color: $elexon-purple;
}

main.container.content {
  margin-bottom: 0;
}
